import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  BusEventBase,
  DashboardCursorSync,
  DataHoverClearEvent,
  DataHoverEvent,
  DataHoverPayload,
  dateTime,
  LegacyGraphHoverClearEvent,
  LegacyGraphHoverEvent,
  PanelProps,
} from '@grafana/data';
import { Subscription } from 'rxjs';
import { getTemplateSrv, getLocationSrv } from '@grafana/runtime';
import { SimpleOptions, defaults, getLayout } from 'types';
import merge from 'deepmerge';
import _ from 'lodash';
import { useTheme2, usePanelContext, useStyles2 } from '@grafana/ui';

import ExponentialRegression from 'ml-regression-exponential';
import { PolynomialRegression } from 'ml-regression-polynomial';
import { RandomForestRegression as RFRegression } from 'ml-random-forest';
import { RobustPolynomialRegression } from 'ml-regression-robust-polynomial';
import helpers, { getOffsetInMs } from './helpers';
// tslint:disable
import Plot from 'react-plotly.js';
// @ts-ignore
import Plotly from 'plotly.js/dist/plotly';
import { PlotData, PlotMouseEvent } from 'plotly.js';
//declare plotly as global
declare global {
  interface Window {
    Plotly: any;
    //LocationSrv: any;
  }
}
window.Plotly = Plotly;

let templateSrv: any = getTemplateSrv();

// typeguard for number
const isNumber = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

interface Props extends PanelProps<SimpleOptions> { }

interface EventOrigin {
  eventBus: any;
  filter: () => boolean;
  filterConfig: any;
  path: string[];
}

export const SimplePanel: React.FC<Props> = (props) => {
  const [hoverState] = useState<{ event: BusEventBase }>();
  const { eventBus, sync } = usePanelContext();
  const theme = useTheme2();
  const { data: _data, timeZone, options } = props;

  const [plot, setPlot] = useState<Readonly<HTMLElement> | null>(null);

  const handleHoverEvent = useCallback(
    (e: DataHoverEvent | DataHoverClearEvent) => {
      if (options.disableSharedTooltip) {
        return;
      }
      let origin = e.origin as any as EventOrigin | undefined;
      if (origin?.path[0] !== `panel:${props.id}`) {
        if (plot?.id) {
          if (isNumber(e?.payload?.point?.time)) {
            const timeValue = e.payload.point.time as number;
            const offset = getOffsetInMs(timeZone, timeValue);
            // Adjust the time value with the offset in ms
            const time = timeValue + offset;
            Plotly.Fx.hover(plot.id, { xval: time });
          } else {
            Plotly.Fx.hover(plot.id, []);
          }
        }
      }
    },
    [plot, props.id, timeZone, options]
  );

  useEffect(() => {
    const subscription = new Subscription();
    if (sync && sync() !== DashboardCursorSync.Off) {
      subscription.add(eventBus.subscribe(DataHoverEvent, handleHoverEvent));
      subscription.add(eventBus.subscribe(DataHoverClearEvent, handleHoverEvent));
      subscription.add(eventBus.subscribe(LegacyGraphHoverEvent, handleHoverEvent));
      subscription.add(eventBus.subscribe(LegacyGraphHoverClearEvent, handleHoverEvent));
    }
    return () => {
      subscription.unsubscribe();
    };
  }, [eventBus, sync, handleHoverEvent]);

  const onHover = useCallback(
    (e: PlotMouseEvent) => {
      if (options.disableSharedTooltip) {
        return;
      }
      if (e.points) {
        // Get the time value of the pointer, and not the nearest point
        const timeValue = (e as any).xvals[0];
        const offset = getOffsetInMs(timeZone, timeValue);
        // Adjust the time value with the offset
        const time = timeValue - offset;

        // Build the payload and publish the event
        let payload: DataHoverPayload = {
          point: {
            time: time,
          },
        };

        let event = new DataHoverEvent(payload);
        eventBus.publish(event);
      }
    },
    [eventBus, timeZone, options]
  );

  const onUnhover = useCallback(() => {
    if (options.disableSharedTooltip) {
      return;
    }
    let event = new DataHoverClearEvent();
    eventBus.publish(event);
  }, [eventBus, options]);

  //Get all variables
  const variables = useMemo(() => {
    const variables = {
      //interval: templateSrv.getBuiltInIntervalValue(),//dataSource.templateSrv.builtIns.__interval.value,
      __from: props.replaceVariables('$__from'),
      __to: props.replaceVariables('$__to'),
      __interval: props.replaceVariables('$__interval'),
      __interval_ms: props.replaceVariables('$__interval_ms'),
    } as any;
    templateSrv.getVariables().forEach((elt: any) => {
      variables[elt.name] = elt.current.text;
    });
    return variables;
  }, [props]);

  let config = options.config || defaults.config;
  let data = options.data || defaults.data;

  let layoutString = props.replaceVariables(JSON.stringify(props?.options?.layout), {}, 'text');

  let layout = useStyles2(getLayout(layoutString));

  const [parameters, error] = useMemo(() => {
    let frames = options.frames || defaults.frames;
    let error: any;
    let parameters: any;
    parameters = [options.data, layout, config, frames, undefined];
    try {
      if (options.script !== '') {
        let f = new Function(
          'data,variables,layout,ml,colours,hoverState,onHover,toDateTime,helpers,panelProps',
          options.script
        );
        parameters = f(
          _data,
          variables,
          layout,
          {
            ExponentialRegression,
            PolynomialRegression,
            RFRegression,
            RobustPolynomialRegression,
          },
          Array(20).map((_, i) => helpers(theme).getPlotlyColor(i)),
          hoverState?.event,
          onHover,
          (e) => dateTime(e),
          helpers(theme),
          props
        );
        if (!parameters) {
          throw new Error('Script must return values');
        }
      }
    } catch (e: any) {
      error = e;
      console.error(e);

      //Can't update chart when script is changing if throw error?!?
      //throw new Error('There\'s an error in your script. Check the console to see error\'s details');
    } finally {
      return [parameters, error];
    }
  }, [options, props, _data, variables, layout, onHover, hoverState?.event, theme, config]);

  useEffect(() => {
    const element = plot?.getElementsByClassName('drag')[0];
    const eventHandler = parameters?.onUnhover ? parameters?.onUnhover : onUnhover;
    element?.addEventListener('mouseleave', eventHandler);
    return () => {
      element?.removeEventListener('mouseleave', eventHandler);
    };
  }, [plot, parameters, onUnhover]);

  const updateChart = useCallback((data, layout, config) => {
    Plotly.react('plotly-' + props.id, data, layout, config);
  }, [props.id]);

  const combineMerge = (target, source, options) => {
    const destination = target.slice();

    source.forEach((item, index) => {
      if (typeof destination[index] === 'undefined') {
        destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
      } else if (options.isMergeableObject(item)) {
        destination[index] = merge(target[index], item, options);
      } else if (target.indexOf(item) === -1) {
        destination.push(item);
      }
    });
    return destination;
  };

  let display: any;

  if (error) {
    let matches = error.stack.match(/anonymous>:.*\)/m);
    let lines = matches ? matches[0].slice(0, -1).split(':') : null;
    display = (
      <div>
        There&apos;s an error in your script : <br />
        <span style={{ color: '#D00' }}>{error.toString()}</span>{' '}
        {lines ? '- line ' + (parseInt(lines[1], 10) - 2) + ':' + lines[2] : ''} (Check your console for more details)
      </div>
    );
  } else {
    display = (
      <Plot
        divId={`plotly-${props.id}`}
        style={{
          width: '100%',
          height: '100%',
        }}
        data={parameters.data ? merge(data, parameters.data, { arrayMerge: combineMerge }) : data}
        frames={
          parameters.frames
            ? merge(data, parameters.frames, { arrayMerge: combineMerge })
            : ((options.frames || defaults.frames) as any)
        }
        layout={{ ...(parameters?.layout ?? layout), autosize: true, height: props.height }}
        config={parameters.config ? merge(config, parameters.config) : config}
        useResizeHandler={true}
        onUpdate={(figure, graphDiv) => {
          setPlot(graphDiv);
        }}
        onLegendClick={e => {
          let data = e.data.map((d, i) => {
            if (d.name === e.data[e.expandedIndex].name) {
              return {
                ...d,
                visible: (e.data[e.expandedIndex] as Partial<PlotData>).visible === 'legendonly' ? true : 'legendonly'
              }
            }
            return d;
          });
          updateChart(data, e.layout, e.config);
          return false;
        }}
        onHover={parameters.onHover ? parameters.onHover : onHover}
        onClick={(data) => {
          let f = new Function('data', 'getLocationSrv', 'getTemplateSrv', options.onclick);
          f(data, getLocationSrv, getTemplateSrv);
        }}
      ></Plot>
    );
  }
  return display;
};

export default React.memo(SimplePanel);
