import merge from 'deepmerge';
import { GrafanaTheme2 } from '@grafana/data';
import { Layout as PlotlyLayout, Data as PlotlyData, Config as PlotlyConfig, Frame as PlotlyFrame } from 'plotly.js';

export interface SimpleOptions {
  title: string;
  layout: Partial<PlotlyLayout>;
  config: Partial<PlotlyConfig>;
  data: PlotlyData[];
  frames: PlotlyFrame[];
  script: string;
  onclick: string;
  disableSharedTooltip: boolean;
}

export type EditorCodeType = string | undefined;
export type EditorLanguageType = 'javascript' | 'html' | 'json' | undefined;

export const defaults: SimpleOptions = {
  title: 'title',
  disableSharedTooltip: false,
  layout: {
    margin: { l: 55, r: 0, b: 30, t: 5, pad: 0 },
    xaxis: {
      title: ' ',
    },
    yaxis: {
      title: ' ',
    },
  },
  config: {
    displayModeBar: false,
  },
  data: [],
  frames: [],
  script: `
const time_field = data.series[0].fields.find(
  (f) => f.name.trim().toLowerCase() == "time"
);
const time = time_field?.values
  .toArray()
  .map((item) => time_field.display(item).text);

const dataTraces = data.series[0].fields.slice(1).map((field, index) => {

  return {
    x: time,
    y: field.values.toArray(),
    type: "scatter",
    mode: "lines",
    name: field.name
  };
});
  
return {data:dataTraces,layout};`,
  onclick: `
const domParser = new DOMParser();
const html = domParser.parseFromString(data.points?.[0]?.text, 'text/html');
const link = html?.querySelectorAll('[data-link]')?.[0]?.dataset?.['link']
if (link) {
  window.open(link, '_blank').focus();
}
`,
};

export const getLayout: (layoutString) => (theme: GrafanaTheme2) => Partial<PlotlyLayout> =
  (layoutString) => (theme: GrafanaTheme2) => {
    let layout: Partial<PlotlyLayout> = {
      paper_bgcolor: theme.colors.background.primary,
      plot_bgcolor: theme.colors.background.primary,
      autosize: true,
      legend: { orientation: 'h', xanchor: 'right', y: 1.1, x: 1 },
      margin: {
        l: 55,
        r: 0,
        b: 40,
        t: 5,
        pad: 0,
      },
      font: {
        color: theme.colors.text.primary,
      },
      xaxis: {
        gridcolor: theme.colors.background.secondary,
        zerolinecolor: theme.colors.background.secondary,
        showgrid: true,
        showticklabels: true,
        type: 'date',
      },
      yaxis: {
        gridcolor: theme.colors.background.secondary,
        zerolinecolor: theme.colors.background.secondary,
        zerolinewidth: 4,
        autorange: true,
        hoverformat: '.1f',
        showgrid: true,
        showticklabels: true,
        type: 'linear',
      },
      hovermode: 'x unified',
    };

    if (layoutString?.length) {
      layout = merge(layout, (JSON.parse(layoutString) as PlotlyLayout) || {});
    }
    return layout;
  };
