import { dateTime, GrafanaTheme2, TimeZone } from '@grafana/data';

export const getNameAndUnit = (input: string, defaults?: { name?: string; unit?: string }) => {
  const name = defaults?.name ?? input.replace(/\[([^)]+)\]/, '');
  const unit = defaults?.unit ?? input.match(/\[([^)]+)\]/)?.[1];

  return { name, unit };
};

export const getDateTime = dateTime;

// Plotly ignores the timezone, so we need to adjust the time, so that the hover events are in the correct timezone
// Moment.js is used behind the scenes in Grafana, so we use the tz function to adjust the time
// Grafana adds a custom timezone so special handling if the timezone is set to browser
export const getOffsetInMs: (timeZone: TimeZone, time: number) => number = (timeZone, time) => {
  let timeWithZone = dateTime(time);
  // If the timezone is not browser, convert to a moment object with utc timezone and then convert to the correct timezone
  if (timeZone !== 'browser') {
    // @ts-ignore
    timeWithZone = timeWithZone.utc().tz(timeZone);
  }
  return timeWithZone.utcOffset() * 60 * 1000;
};

export const getPlotlyColor = (index) => {
  const colors = [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
  ];
  return colors[index % colors.length];
};

export const getFuelCategoryColor = (theme: GrafanaTheme2) => (category: string) => {
  switch (category) {
    case 'DieselGasOil':
      // Yellow color adjusted for dark theme
      return theme.isDark ? '#FFFF99' : '#FFD700';
    case 'LFO':
      // Dark brown color adjusted for dark theme
      return theme.isDark ? '#8B4513' : '#654321';
    case 'HFO':
      return '#000000'; // Black
    case 'LPG':
    case 'LNG':
    case 'Methanol':
    case 'Ethanol':
      return '#FFFFFF'; // Colorless
    case 'LPGB':
      // Pink color adjusted for dark theme
      return theme.isDark ? '#FFC0CB' : '#FFB6C1';
    default:
      // Gray color adjusted for dark theme
      return theme.isDark ? '#808080' : '#A9A9A9';
  }
};

export const getHoverLabelFunction = (theme: GrafanaTheme2) => (color: string | number) => {
  if (typeof color === 'number') {
    color = getPlotlyColor(color);
  }
  return {
    bgcolor: theme.colors.background.primary, // Grafana background primary #191b1f
    bordercolor: color,
    font: {
      color: theme.colors.text.primary, // Grafana text primary #d9d9d9
    },
  };
};

export const getEventType = (e) => {
  switch (e) {
    case 'BunkerManualEvent':
      return 'Bunker';
    case 'BunkerMeasuredEvent':
      return 'Bunker';
    case 'FuelChangeEvent':
      return 'Fuel change';
    case 'LeftBerthEvent':
      return 'Left berth';
    case 'ArrivedAtBerthEvent':
      return 'Arrived at berth';
    case 'CargoOperationEvent':
      return 'Cargo operation';
    case 'ReportEvent':
      return 'ROB';
    case 'SeaPassageInterruptedEvent':
      return 'Sea passage interrupted';
    case 'SeaPassageRecommencedEvent':
      return 'Sea passage recommenced';
    case 'ModeChangeEvent':
      return 'Mode change';
    case 'CorrectionEvent':
      return 'Correction';
    case 'FuelRegistrationEvent':
      return 'Fuel registration';
    default:
      return e;
  }
};

export const getEventText = (e) => {
  switch (e.type) {
    case 'LeftBerthEvent':
      return `<b>Left berth</b><br>
<br>Port: ${e.port_name ?? '-'}
<br>Locode: ${e.port_locode_part ?? '-'}`;
    case 'ArrivedAtBerthEvent':
      return `<b>Arrived at berth</b><br>
<br>Port: ${e.port_name ?? '-'}
<br>Locode: ${e.port_locode_part ?? '-'}`;
    case 'BunkerManualEvent':
    case 'BunkerMeasuredEvent':
      return `<b>Bunker</b><br>
<br>Port: ${e.port_name ?? '-'}
<br>Stream: ${e.bunker_stream_name ?? '-'}
<br>Product: ${e.product_name ?? '-'}
<br>${e.bunker_stream_direction === 'Supplier' ? 'Receiver' : 'Supplier'}: ${e.bunker_supplier ?? '-'}
<br>Amount: ${Math.floor(e.bunker_total_mass) ? Math.floor(e.bunker_total_mass).toLocaleString() + ` ${e.mass_unit}` : '-'}`;
    case 'FuelChangeEvent':
      return `<b>Fuel change</b><br>
<br>Fuel: ${e.fuel_name ?? '-'}
<br>Consumer: ${e.consumer_name ?? '-'}`;
    case 'CargoOperationEvent':
    case 'ReportEvent':
    case 'SeaPassageInterruptedEvent':
    case 'SeaPassageRecommencedEvent':
    case 'ModeChangeEvent':
    case 'CorrectionEvent':
    case 'FuelRegistrationEvent':
      return `${getEventType(e.type)}<br>Comment: <br>${e.comment}`;
    default:
      return e.type;
  }
};

export const getEventColor = (theme: GrafanaTheme2) => (e) => {
  switch (e.type) {
    case 'LeftBerthEvent':
      // Purple color adjusted for dark theme
      return theme.isDark ? '#8A2BE2' : '#332288';
    case 'ArrivedAtBerthEvent':
      // Blue color adjusted for dark theme
      return theme.isDark ? '#88CCEE' : '#336699';
    case 'BunkerManualEvent':
      return '#44AA99';
    case 'BunkerMeasuredEvent':
      return '#44AA99';
    case 'CargoOperationEvent':
      return '#117733';
    case 'FuelChangeEvent':
      return '#999933';
    case 'ReportEvent':
      return '#DDCC77';
    case 'SeaPassageInterruptedEvent':
      return '#CC6677';
    case 'SeaPassageRecommencedEvent':
      return '#882255';
    case 'ModeChangeEvent':
      return '#AA4499';
    case 'CorrectionEvent':
      return '#C81D13';
    case 'FuelRegistrationEvent':
      return '#BAB0AB';
    default:
      return '#DDDDDD';
  }
};

export default (theme: GrafanaTheme2) => ({
  getHoverLabelFunction,
  getPlotlyColor,
  getEventType,
  getEventText,
  getEventColor: getEventColor(theme),
  getNameAndUnit,
  getFuelCategoryColor: getFuelCategoryColor(theme),
  getOffsetInMs,
  getDateTime,
  getHoverLabel: getHoverLabelFunction(theme),
});
